<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input
          v-model="searchParams.name"
          size="small"
          style="width: 150px; margin: 5px"
          placeholder="朝代"
          allowClear
        ></a-input>
        <a-button @click="search" size="small" style="margin-left: 10px"
          >搜索</a-button
        >
      </div>

      <!-- <div>
                <a-button @click="openModal({})" size='small'>新增朝代</a-button>
            </div> -->
    </div>
    <div class="table-content" style="padding-top: 20px">
      <a-table
        @change="changePage"
        :pagination="pagination"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :rowKey="(record, n) => record.id || n"
      >
        <div slot="setup" slot-scope="row">
          <a @click="openModal(row)" style="margin-right: 5px">编辑</a>
          <!-- <a-popconfirm
                        title="Are you sure delete this item?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="remove(row.id)"
                    >
                    <a style='margin-left:5px'>删除</a>
                    </a-popconfirm> -->
        </div>
      </a-table>
    </div>

    <a-modal
      v-model="visible"
      :title="innerTitle"
      ok-text="确认"
      cancel-text="取消"
      @cancel="resetForms"
      @ok="hideModal"
    >
      <a-form-model
        ref="ruleForm"
        :model="forms"
        :rules="rules"
        v-bind="layout"
      >
        <a-form-model-item label="大币种" prop="coinNames">
          <a-checkbox-group
            v-model="forms.coinIdList"
            name="checkboxgroup"
            :options="coinsOptions"
            @change="onChange"
          />
        </a-form-model-item>
        <a-form-model-item label="新增大币种" prop="coinIds">
          <a-select
            @search="searchData"
            ref="addse"
            v-model="addCoinId"
            :filter-option="untils.filterOption"
            showSearch
            @change="selectCoinId"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item of currency_big_list"
              :key="item.id"
              :value="item.sid"
              >
              {{ item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}
              <span v-if="item.dynastyName"> {{ item.dynastyName }}</span>
              <span v-if="item.dynastyName">{{ item.rulerName }}</span
              ></a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="排序" prop="sort">
          <a-input
            v-model="forms.sort"
            size="small"
            placeholder="排序"
          ></a-input>
        </a-form-model-item>
        
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {getBigCoinList} from "@/axios/apis"

export default {
  data() {
    return {
      currency_big_list: [],
      coinsOptions:[],
      addCoinId: "", //新增币种id
      columns: [
        { title: "ID", dataIndex: "id" },
        { title: "名称", dataIndex: "name" },
        { title: "大币种", dataIndex: "coinNames" },
        { title: "排序", dataIndex: "sort" },
        // {title:'币种',dataIndex:'coinNames'},
        // {title:'类型',
        //     customRender:(text, row, index) => {
        //         return <span>{row.dynastyCountryType == 2 ? '国外' : '国内'}</span>
        //     }
        // },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      searchParams: {
        pageSize: 10,
        pageNum: 1,
        name: "",
        dynastySort: "",
      },
      rules: {
        dynastyName: [
          { required: true, message: "please enter", trigger: "blur" },
        ],
        dynastySort: [
          { required: true, message: "please enter", trigger: "blur" },
        ],
        dynastyCountryType: [
          { required: true, message: "please select", trigger: "change" },
        ],
      },
      pagination: {
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      forms: {},
      visible: false,
      loading: false,
      innerTitle: "",
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getData(this.searchParams);
  },
  methods: {
    // 选择cionid
    selectCoinId(data){
      const coin = this.currency_big_list.find(el=>{
        return data == el.sid
      })
      // 去重
      const index = this.forms.coinIdList.findIndex(el=>{
        return coin.id == el
      })
      if (index > -1) {
        return this.$message.warning("该币种已经添加，请勿重复添加")
      }
      this.forms.coinIdList.push(coin.id)
      this.coinsOptions.push({
        label: coin.coinKindName,
        value: coin.id
      })
    },
    // 
    onChange(){
    },
    // 搜索大币种
    async searchData(inner) {
      const res = await getBigCoinList(inner)
      if (res.status !== '200') return;
      const { records } = res.data;
      this.currency_big_list = records;
    },
    openModal(row) {
      this.addCoinId = ""
      const { id } = row;
      this.innerTitle = id ? "编辑朝代" : "新增朝代";
      this.forms = JSON.parse(JSON.stringify(row));
      // 币种数据
      const coinNames = this.forms.coinNames.split(",")
      this.coinsOptions = this.forms.coinIdList.map((el, index)=>{
        return {
          label: coinNames[index],
          value: el
        }
      })
      this.visible = true;
    },
    async getData(params) {
      this.loading = true;
      const res = await this.axios("/dq_admin/knowledgeDynasty/list", {
        params: params,
      });
      this.loading = false;
      const { records, total } = res.data;
      this.data = records;
      this.pagination.total = total;
    },
    search() {
      this.searchParams.pageNum = 1;
      this.pagination.current = 1;
      this.getData(this.searchParams);
    },
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.searchParams.pageNum = current;
      this.searchParams.pageSize = pageSize;
      this.getData(this.searchParams);
    },
    resetForms() {
      this.forms = {};
      this.$refs.ruleForm.resetFields();
    },
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit(this.forms);
        } else {
          return false;
        }
      });
    },
    submit(forms) {
      const { id } = forms;
      const url = id ? "/dq_admin/knowledgeDynasty/edit" : "/dq_admin/dynasty/add";
      this.axios.post(url, forms).then((res) => {
        if (res.status == "200") {
          this.$message.success(res.message);
          this.visible = false;
          this.addCoinId = ""
          this.getData(this.searchParams);
        }
      });
    },
    async remove(params) {
      const res = await this.axios("/dq_admin/dynasty/del", {
        params: { id: params },
      });
      if (res.status == "SUCCESS") {
        this.$message.success(res.message);
        this.getData(this.searchParams);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-attr {
  display: flex;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}
</style>