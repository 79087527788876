import instance from "@/axios"

/**  获取积分明细 */
export const getScoreLineInfo = async (params) => {
    return instance({
        url: '/dq_admin/userCreditDetail/detailPageListByUserId',
        method: 'GET',
        params: params
    })
}
/**  领先数据 */
export const getUserScoreInfo = async (params) => {
    return instance({
        url: '/dq_admin/userCredit/userCreditDetailList',
        method: 'GET',
        params: params
    })
}

/** 用户管理-禁言 */
export const userBanSpeakApi = async (params) => {
    return instance({
        url: '/dq_admin/user/mute',
        method: 'get',
        params: params
    })
}

/** 用户管理-培训 */
export const userStudyApi = async (params) => {
    return instance({
        url: '/dq_admin/userShopQuestionAnswer/edit',
        method: 'post',
        data: params
    })
}
/** 用户管理-取消培训 */
export const cancelUserStudyApi = async (params) => {
    return instance({
        url: '/dq_admin/userShopQuestionAnswer/delById',
        method: 'get',
        params: params
    })
}

/** 保证金管理-占用明细 */
export const getBondPriceOccupationLineApi = async (params) => {
    return instance({
        url: '/dq_admin/userDeposit/depositOccupationDetailList',
        method: 'get',
        params: params
    })
}

/** 编辑用户备注--新增-编辑 */
export const postEditorUserRemarkApi = async (params) => {
    return instance({
        url: '/dq_admin/userRemark/add',
        method: 'POST',
        data: params
    })
}
/** 获取用户备注列表 */
export const getUserRemarkListApi = async (userId) => {
    return instance({
        url: '/dq_admin/userRemark/list',
        method: 'get',
        params: {
            userId: userId,
            pageNum: 1,
            pageSize: 1000
        }
    })
}

/** 白名单管理，推送消息模版 */
export const getUserWhitelistMessageTemplateApi = async (params) => {
    return instance({
        url: '/dq_admin/platformPushDetail/dynamicContent',
        method: 'post',
        data: params
    })
}

/** 解绑微信 */
export const putUserUnbindWeChatApi = async (params) => {
    return instance({
        url: '/dq_admin/user/unbindWeiXin',
        method: 'get',
        params: params
    })
}

/** 更换手机号 */
export const putUserChangePhoneApi = async (params) => {
    return instance({
        url: '/dq_admin/user/editUserPhone',
        method: 'get',
        params: params
    })
}